import React from "react";
import {IFeatureSection} from "../templates/features-z-pattern/feature-section.interface";
import TextFormatter from "../../utils/TextFormatter";
import CustomLink from "../shared/custom-link/CustomLink";
import {GatsbyImage} from "gatsby-plugin-image";

export interface IHowItWorks extends IFeatureSection {
  button: {
    label: string,
    url: string,
  }
}

const HowItWorks = ({data: {title, button, image, description}}: { data: IHowItWorks }) => {
  const formattedTitle = TextFormatter.concatOrphans(title) || title;
  const formattedDescription = TextFormatter.concatOrphans(description.data.description) || description.data.description;
  return (
    <section className={'flex justify-center items-center bg-white even:bg-tertiary-40 w-full'}>
      <div className={'flex flex-col items-center lg:flex-row px-5 sm:px-20 md:px-36 py-20 md:py-28 gap-y-11 gap-x-14 xl:gap-x-24 max-w-[1520px]'}>
        <div className={'flex flex-col lg:w-1/2 gap-y-10'}>
          <div className={'text-4xl xl:text-5xl font-head text-black'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
          <div className={'text-neutrals-40 text-lg'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
          <CustomLink className={'mtr-btn-large mtr-btn-[secondary] w-fit'} isInternal to={button.url}>
            <span>{button.label}</span>
          </CustomLink>
        </div>

        <div className={'flex w-full lg:w-1/2'}>
          <GatsbyImage className={'rounded'} alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData} />
        </div>
      </div>
    </section>
  )
}

export default HowItWorks;
